import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LifeItem from '../../components/lifeItem/LifeItem.js'

import './lifeAtBH.scss'

class LifeAtBH extends Component {
  render() {
    const data = this.props.data
    return (
      <React.Fragment>
        <div className='size-54 LifeAtBH__title embed-title text-center bold'>
          Life at Blue Harvest
        </div>

        <div className='size-20 embed-title italic extra-lh LifeAtBH__description text-center' />

        <div className='LifeAtBH__list'>
          {data.map((data, i) => (
            <LifeItem key={i} data={data} />
          ))}
        </div>
      </React.Fragment>
    )
  }
}

const query = graphql`
  query lifeAtBH {
    allSanityLifeAtBh(limit: 20, sort: { order: DESC, fields: priority }) {
      edges {
        node {
          title
          image {
            alt
            asset {
              _id
              url
            }
          }
        }
      }
    }
  }
`

export default () => {
  return (
    <StaticQuery
      key='lifeAtBH'
      query={query}
      render={data => {
        return <LifeAtBH data={data.allSanityLifeAtBh.edges.map(v => v.node)} />
      }}
    />
  )
}
