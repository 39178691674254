import propTypes from 'prop-types'
import React from 'react'
import './LifeItem.scss'

export default class LifeItem extends React.Component {
  static propTypes = {
    data: propTypes.any,
  }

  render() {
    const {
      data: { image = {}, title = '' },
    } = this.props

    const { alt, asset = {} } = image
    const { url } = asset

    if (!url) {
      // no image
      return false
    }

    const style = {
      backgroundImage: `url("${url}")`,
    }

    return (
      <div className='LifeItem'>
        <div className='LifeItem__image' alt={alt} style={style} />
        <div className='LifeItem__title size-16 embed-title'>{title}</div>
      </div>
    )
  }
}
