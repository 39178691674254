import React from 'react'
import Template from '../template/Template'
import LifeAtBH from '../pageComponents/careers/lifeAtBH'

export default () => {
  return (
    <Template>
      <LifeAtBH />
    </Template>
  )
}
